/**
 * Adaptive Placeholders
 */

(function($) {
  'use strict';

  Drupal.behaviors.adaptivePlaceholders = {
    attached: false,

    labelMode: function($input) {
      $input.addClass('adpl__mode-label');
      $input.removeClass('adpl__mode-placeholder');
    },

    placeholderMode: function($input) {
      $input.removeClass('adpl__mode-label');
      $input.addClass('adpl__mode-placeholder');
    },

    toggleMode: function($input) {
      var self = this;

      if ($input.val() === '') {
        self.placeholderMode($input);
      } else {
        self.labelMode($input);
      }
    },

    bindEvents: function($input) {
      var self = this;

      // swap out placeholder/label classes on focus in or out
      $input.on('focusin', function() {
        self.labelMode($input);
      });

      $input.on('focusout', function() {
        self.toggleMode($input);
      });

      $input.on('change', function() {
        self.toggleMode($input);
      });
    },

    setupDOM: function($inputs) {
      var self = this;

      $inputs.each(function() {
        var $input = $(this);
        if (!$input.hasClass('adpl--processed')) {
          var $label = $input.siblings('label');
          var placeholder = $input.attr('placeholder') || $label.attr('placeholder');

          // input needs a placeholder
          if (!placeholder) {
            return true;
          }

          // if label exists
          if ($label.length > 0) {
            $label.remove(); // hold in space
          } else {
            // if label does not exist, build it
            var id = $input.attr('id');
            if (!!id) {
              $label = $('<label class="label" for="' + id + '">' + placeholder + '</label>');
            } else {
              // if there is no label, and no id on the input, then we cannot proceed
              return true;
            }
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('placeholder')) {
            $label.attr('placeholder', placeholder);
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('alt')) {
            $label.attr('alt', placeholder);
          }

          // ensure that label contains an inner span.label-content wrapping the text
          if ($label.find('span.label-content').length < 1) {
            $label.wrapInner('<span class="label-content"></span>');
          }

          // position the label after the input, required for proper z-index
          $label.insertAfter($input);

          // cleanup inputs
          $input.removeAttr('placeholder');

          // set states, bind events
          self.placeholderMode($input);
          self.bindEvents($input);
          self.toggleMode($input);

          // add CSS class for styling
          $input.addClass('adpl--processed');
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      if ($('html').hasClass('no-placeholder')) {
        return;
      }

      var $inputs = $('input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea', context).not('.no-adpl');
      self.setupDOM($inputs);
    }
  };
})(jQuery);
